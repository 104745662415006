import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


export function Footer(props: any) {

    const { t } = useTranslation();

    const GetAppInformation = () => {
        return axios.get('AppInformation', {});
    };

    interface AppSetting {
        name: string;
        value: string
    };

    const [settings, setSettings] = useState({ Company: '', AppName: '', AppVersion: '', Server: '' });

    useEffect(() => {

        const processData = (returnedData: AppSetting[]) => {
            let newData: AppSetting[];
            newData = [...returnedData];

            var company: string = newData.find(i => {
                return i.name == 'Company';
            })?.value || '';
            var appName: string = newData.find(i => {
                return i.name == 'AppName';
            })?.value || '';
            var appVersion: string = newData.find(i => {
                return i.name == 'AppVersion';
            })?.value || '';
            var server: string = newData.find(i => {
                return i.name == 'Server';
            })?.value || '';

            setSettings({
                Company: company,
                AppName: appName,
                AppVersion: appVersion,
                Server: server
            });
        };

        GetAppInformation()
            .then(result => processData(result.data));

    }, []);


    return (
        <div className='d-flex justify-content-center p-1'>
            <div className="text-secondary">
                <span>{settings.AppName}</span> <span>{settings.AppVersion}</span> | <span>{settings.Server}</span> | <span>&copy; {new Date().getFullYear()} {settings.Company}</span>
            </div>
        </div>
    );

}