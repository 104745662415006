// import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import { useTranslation } from 'react-i18next';
import { LangSelector } from './LangSelector'


export function NavMenu() {

  const { t } = useTranslation();
  return (

    <Navbar bg="light" expand="lg">
      <LinkContainer to="/">
        <Navbar.Brand><span className="p-1">{t('sf Online Order')}</span></Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>

          <LinkContainer to="/shoppingapp">
            <Nav.Link><span className="material-icons-outlined align-text-bottom">shopping_basket</span>{t('shopping')}</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/customerOrder">
            <Nav.Link><span className="material-icons-outlined align-text-bottom">description</span>{t('Orders')}</Nav.Link>
          </LinkContainer>
          <a href='Server/Setup' className='nav-link'>
            <span className="material-icons-outlined align-text-bottom">settings</span>{t('Setup')}
          </a>
          <LoginMenu></LoginMenu>
        </Nav>
        <Nav className="ms-auto align-items-center">
          <LangSelector />
        </Nav>
      </Navbar.Collapse>
    </Navbar>

  );

}
