import axios from 'axios';
import { ShoppingCartLineType } from './Models';
import authService from './api-authorization/AuthorizeService';

const AuthHeader = async () => {
    const token = await authService.getAccessToken();
    const jwtHeader = {
        headers:
            { 'Authorization': `Bearer ${token}` }
    };
    return jwtHeader;
};

export async function GetCategories() {
    const authHeader = await AuthHeader();
    return axios.get('Categories', authHeader);

}

export async function GetProducts() {
    const authHeader = await AuthHeader();
    return axios.get('Products', authHeader);

}

export async function GetProductsByCategory(id:string) {
    const authHeader = await AuthHeader();
    return axios.get(`Products/ByCategory/${id}`, authHeader);

}

export async function ShoppingCartForUser(userName:string) {
    const authHeader = await AuthHeader();
    return axios.get(`/ShoppingCart/${userName}`, authHeader);

}

export async function ShoppingCartItemCount(userName:string) {
    const authHeader = await AuthHeader();
    return axios.get(`/ShoppingCart/GetCount/${userName}`, authHeader);

}

export async function ShoppingCartAddItem(userName:string, item:ShoppingCartLineType) {
    const authHeader = await AuthHeader();
    return axios.post(`/ShoppingCart/${userName}`, item, authHeader);
}

export async function ShoppingCartDeleteItem(userName:string, lineNo:number) {
    const authHeader = await AuthHeader();
    return axios.delete(`/ShoppingCart/${userName}/${lineNo}`, authHeader);
}

export async function ShoppingCartDelete(userName:string) {
    const authHeader = await AuthHeader();
    return axios.delete(`/ShoppingCart/${userName}`, authHeader);
}

export async function ShoppingCartSaveOrder(userName:string) {
    const authHeader = await AuthHeader();
    return axios.post(`/ShoppingCart/SaveOrder/${userName}`, {}, authHeader);
}

export async function GetCustomerOrder(userName:string) {
    const authHeader = await AuthHeader();
    return axios.get(`/CustomerOrder/${userName}`, authHeader);

}


export async function GetCustomerOrderLines(oriderId:number) {
    const authHeader = await AuthHeader();
    return axios.get(`/CustomerOrder/GetLines/${oriderId}`, authHeader);

}

export async function UserInformation() {
    const authHeader = await AuthHeader();
    return axios.get(`/UserInformation`, authHeader);

}
