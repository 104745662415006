import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import authService from './api-authorization/AuthorizeService';
import { useTranslation } from 'react-i18next';
import { ShoppingCartItemCount } from './DataAccess';


export function ShoppingCartIndicator(props: { addedItem: { productCode: string, qty: number } }) {
    const { t } = useTranslation();
    const [itemCount, setItemCount] = useState(0);

    const [loginState, setLoginState] = useState({
        isAuthenticated: false,
        userName: ''
    });

    const populateState = async () => {

        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        setLoginState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    useEffect(() => {
        const subscription = authService.subscribe(() => populateState());
        populateState();  // Refresh state once!
        return (() => authService.unsubscribe(subscription));
    }, []);


    useEffect(() => {
        if (loginState.isAuthenticated) {
            ShoppingCartItemCount(loginState.userName)
                .then(result => {
                    // console.log(result);
                    if (result.status === 200) {
                        setItemCount(result.data as number);
                    }
                }
                );
        }
    }, [loginState, props.addedItem]);

    return (
        <div title={t("Shopping Cart")}>
            {
                loginState.isAuthenticated ?
                    <LinkContainer to='/shoppingcart'>
                        <Nav.Link>
                            <span className="material-icons align-middle">
                                shopping_cart
                            </span>
                            <span>{itemCount} {t("item(s)")}</span>
                        </Nav.Link>
                    </LinkContainer>
                    : ""
            }

        </div>
    );

}