import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export function LanguageControl(props: { currentLang: string, onClick: (lang: string) => void }) {

    const languages = [{ k: 'cht', n: '中文' }, { k: 'eng', n: 'English' }];

    const btns = () => {
        return (
            <ButtonGroup>
                {
                    languages.map(lang => (
                        <ToggleButton onClick={() => props.onClick(lang.k)} 
                            key={lang.k} 
                            value={lang.k}
                            variant="outline-primary"
                            size="sm"
                            type="radio"
                            checked={lang.k === props.currentLang}>
                            {lang.n}
                        </ToggleButton>
                    ))
                }
            </ButtonGroup>
        )
    };


    return (
        <div>
            {btns()}
        </div>
    );


}