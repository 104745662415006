import React, { Component, Fragment } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Translation } from 'react-i18next';


export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {

        return (<Fragment>

            
            <div className="nav-link"><span className="material-icons-outlined align-text-bottom">person</span>{userName}</div>
            

            <Translation>
                {(t, { i18n }) =>
                    <LinkContainer to={logoutPath}>
                        <Nav.Link><span className="material-icons-outlined align-text-bottom">logout</span>{t("Logout")}</Nav.Link>
                    </LinkContainer>
                }
            </Translation>

        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {

        return (<Fragment>

            <LinkContainer to={registerPath}>
                <Nav.Link>Register</Nav.Link>
            </LinkContainer>


            <LinkContainer to={loginPath}>
                <Nav.Link>Login</Nav.Link>
            </LinkContainer>

        </Fragment>);
    }
}
