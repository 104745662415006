import React, { useState } from 'react';
import { Shopping } from './Shopping'
import { ShoppingCartIndicator } from './ShoppingCartIndicator'

export function ShoppingApp() {

    const [shoppedItem, addShoppedItem] = useState({productCode:'', qty:0});

    return (
        <div className="d-flex flex-column">
            <div className="align-self-end"><ShoppingCartIndicator addedItem={shoppedItem} /></div>
            
            <Shopping onAddItem={(item) => { addShoppedItem(item) }} />
        </div>
    );

}