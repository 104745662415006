import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

export function AddShopItem(props: { unit?:string, onShopItem: (qty: number) => void }) {

    const { t } = useTranslation();
    const [qty, setQty] = useState(1);
    const handleShopItem = () => {
        props.onShopItem(qty);
        setQty(1); // Reset to 1
    }

    return (
        <InputGroup>
            <Button size='sm' onClick={handleShopItem}><span className="material-icons align-text-bottom">add_shopping_cart</span>{t("AddItem")}</Button>
            <FormControl type="number" value={qty} onChange={(event: any) => setQty(event.target.value)} />
            <span className="input-group-text">{props.unit}</span>
        </InputGroup>
    );


}