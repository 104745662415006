import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageControl } from './LanguageControl';

export function LangSelector(props: any) {

  // const { i18n } = useTranslation();
  const { i18n } = useTranslation();

  // console.log('Current lang:' + i18n.resolvedLanguage);

  const [currentLang, changeCurrentLang] = useState(i18n.resolvedLanguage);

  const selectedLang = (lang: string) => {
      const culture = lang === 'eng' ? 'en-GB' : 'zh-HK';

      // Used by ASP.NET core
      document.cookie=`lang=${culture}`; 

      // Used by i18 scripts
      i18n.changeLanguage(lang);
      document.cookie=`i18next=${lang}`; 

      changeCurrentLang(lang);
  };


  return (
      <div className="p-1">
        <LanguageControl onClick={selectedLang} currentLang={currentLang} />
      </div>
  );

}