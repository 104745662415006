import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
  fallbackLng: 'cht',
  resources: {
    eng: {
      translations: require('./locales/eng/translations.json')
    },
    cht: {
      translations: require('./locales/cht/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['eng', 'cht'];

export default i18n;