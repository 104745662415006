// import { useTranslation } from 'react-i18next';
import { GetCategories } from './DataAccess'
import React, { useState, useEffect } from 'react';
import { CategoryType } from './Models';
import { ListGroup } from 'react-bootstrap';
import './Category.css';

export function Category(props: { category: CategoryType, setCategory: (c: CategoryType) => void }) {
    // const { t } = useTranslation();
    const [categories, fillData] = useState(Array<CategoryType>(0));
    // const [selected, setSelect] = useState('');

    useEffect(() => {
        GetCategories().then(
            result => {
                fillData(result.data);
            }
        );
    }, []);

    return (
        <section>
            <ListGroup variant="flush">
                {
                    categories.map(c =>
                        <ListGroup.Item key={c.code} active={c.code === props.category.code}
                            onClick={() => props.setCategory(c)}>
                            <button className='w-100 text-dark border-0 bg-transparent'>{c.description}</button>
                        </ListGroup.Item>
                    )
                }
            </ListGroup>
        </section>
    );
}