import React, { useState, useEffect } from 'react';
import { Breadcrumb, Collapse, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { CategoryType } from './Models';
import { Category } from './Category';
import { ProductList } from './ProductList';
import authService from './api-authorization/AuthorizeService';
import { ShoppingCartAddItem, UserInformation } from './DataAccess';

export function Shopping(props: { onAddItem: (item: { productCode: string, qty: number }) => void }) {

    const { t } = useTranslation();

    const isMobile = (window.innerWidth < 800);

    const NoCategory: CategoryType = { code: '', description: '' };
    const RecentPurchased: CategoryType = { code: '*RECENT', description: t('Recent Purchased') };

    const [catOpen, setCatOpen] = useState(!isMobile);
    const [category, selectCateory] = useState(RecentPurchased);

    const shopItem = (item: { productCode: string, qty: number }) => {
        authService.getUser().then(
            (user) => {
                ShoppingCartAddItem(user.name, { userName: user.name, lineNo: 0, ...item })
                    .then(() => props.onAddItem(item));
            }
        );
    };

    useEffect(() => {
        authService.getUser().then(
            (user) => {
                interface InfoData {
                    name: string;
                    value: string
                };

                UserInformation()
                    .then((result) => {
                        var infoData: InfoData[] = result.data;
                        var recentPurchasedNumber: string = infoData.find(i => {
                            return i.name == 'RecentPurchasedNumber';
                        })?.value || '0';

                        if (recentPurchasedNumber == '0') {
                            selectCateory(NoCategory);
                        }

                    });

            });
    }, []);

    const onSelectedCatrgory = (cat: CategoryType) => {
        selectCateory(cat);
        if (isMobile) {
            setCatOpen(false);
        }
    };

    return (
        <>
            <div className="row">
                <div className="w-100"><h3 className="text-center" >{t('Shopping Mall')}</h3></div>
                <Breadcrumb className="col-md-12">
                    <Breadcrumb.Item href="#" onClick={() => selectCateory(NoCategory)}>{t("Categories")}</Breadcrumb.Item>
                    {
                        category.code ? (
                            <Breadcrumb.Item active>{category.description}</Breadcrumb.Item>
                        ) : ''
                    }
                </Breadcrumb>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Button
                        onClick={() => setCatOpen(!catOpen)}
                        aria-controls="cat-collapse"
                        aria-expanded={catOpen}
                        className='w-100 mb-1'
                        variant='link'
                    >
                        <span>{t("Select Category")}</span> <span className="material-icons align-middle">{catOpen ? 'unfold_less' : 'unfold_more'}</span>
                    </Button>

                    <Collapse in={catOpen}>
                        <div id="cat-collapse">
                            <Category category={category} setCategory={onSelectedCatrgory} />
                        </div>
                    </Collapse>

                </div>
                <div className="col-md-8">
                    <ProductList category={category.code} shopItem={(item) => shopItem(item)} />
                </div>
            </div>
        </>
    );
}

