import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService';
import { ShoppingCartForUser, ShoppingCartDeleteItem, ShoppingCartDelete, ShoppingCartSaveOrder } from './DataAccess';
import { ShoppingCartLineType } from './Models'
import { Table, Button } from 'react-bootstrap'
import { useFormState } from 'react-final-form';


export function ShoppingCart() {

    const { t } = useTranslation();

    const [items, SetItems] = useState(Array<ShoppingCartLineType>(0));
    const [totalQty, SetTotalQty] = useState(0);
    const [error, SetError] = useState("");
    

    useEffect(() => {
        authService.getUser().then(
            (user) => {
                ShoppingCartForUser(user.name)
                    .then((result) => {
                        let newData: ShoppingCartLineType[];
                        newData = [...result.data];
                        SetItems(newData);

                        const tot = newData.length == 0 ? 0 : (newData.map(c => c.qty)).reduce((total, qty) => total += qty);
                        SetTotalQty(tot);
                    });
            }
        );

    }, []);

    const handleDeleteItem = (item: ShoppingCartLineType) => {
        authService.getUser().then(
            (user) => {
                ShoppingCartDeleteItem(user.name, item.lineNo)
                    .then((result) => {
                        let newData = [...items];
                        const index = newData.findIndex(c => c.lineNo == item.lineNo);
                        newData.splice(index, 1);
                        SetItems(newData);

                        const tot = newData.length == 0 ? 0 : (newData.map(c => c.qty)).reduce((total, qty) => total += qty);
                        SetTotalQty(tot);
                    });
            })
    };

    const handleDelete = () => {
        authService.getUser().then(
            (user) => {
                ShoppingCartDelete(user.name)
                    .then((result) => {
                        let newData = Array<ShoppingCartLineType>(0);
                        SetItems(newData);

                        SetTotalQty(0);
                    });
            })
    };

    const handleSaveOrder = () => {
        authService.getUser().then(
            (user) => {
                ShoppingCartSaveOrder(user.name)
                    .then((result) => {
                        let newData = Array<ShoppingCartLineType>(0);
                        SetItems(newData);
                        SetError("");
                        SetTotalQty(0);
                    },
                        (reason: { message: string, response: { status: number, data: { errors: { [property: string]: string[] } } } }) => {
                            let errorText = `${reason.message}`;
//                            let propeties =  Object.getOwnPropertyNames(reason.response.data.errors);
//                            errorText += ' ' + (propeties.map( p=> reason.response.data.errors[p].join() )).join();
                            SetError(errorText);
                        });
            })
    };


    return (
        <div>
            <div className="w-100"><h3 className="text-center" >{t('Shopping Cart')}</h3></div>
            <div className="text-danger">{error}</div>
            <Table bordered size="sm">
                <thead>
                    <tr>
                        <th>{t("Line")}</th>
                        <th>{t("Code")}</th>
                        <th>{t("Product")}</th>
                        <th>{t("Qty")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {

                        items.map(item =>
                            <tr key={item.lineNo}>
                                <td>{item.lineNo}</td>
                                <td>{item.productCode}</td>
                                <td>{item.productName}</td>
                                <td className="text-end pe-3">{item.qty}</td>
                                <td><Button variant="warning" onClick={() => handleDeleteItem(item)}><span className="material-icons-outlined align-text-bottom">
                                    delete
                                </span>{t("Remove")}</Button></td>
                            </tr>
                        )

                    }
                </tbody>
                <tfoot>
                    <tr className="fw-bold"><td colSpan={3} className="text-end">{t("Total")}:</td><td className="text-end pe-3">{totalQty}</td><td></td></tr>
                </tfoot>
            </Table>
            <div>
                <Button variant="danger" onClick={() => handleDelete()} hidden={ totalQty == 0 ? true : false }><span className="material-icons align-text-bottom">delete_forever</span>{t("Clear all")}</Button>{' '}
                <Button onClick={() => handleSaveOrder()} hidden={ totalQty == 0 ? true : false }  ><span className="material-icons-outlined align-text-bottom">shopping_bag</span>{t("Proceed to Order")}</Button>
            </div>
        </div>
    )

}