import React, { useState, useEffect } from 'react';
import MaterialTable from "material-table";
import { GetProducts, GetProductsByCategory } from './DataAccess';
import { ProductType } from './Models';
import { useTranslation } from 'react-i18next';
import { AddShopItem } from './AddShopItem'

export function ProductList(props: { category: string, shopItem: (item: { productCode: string, qty: number }) => void }) {
    const { t } = useTranslation();

    const columns = [
        {
            title: t("Product Name"),
            cellStyle:{  color: 'navy' },
            render: (rowData: ProductType) => <><span>{rowData.productName}</span><br /><span>{rowData.productCode}</span></>,
        },
        {
            title: t("Add to Order"),
            render: (rowData: ProductType) =>
                <AddShopItem onShopItem={(qty: number) => props.shopItem({ productCode: rowData.productCode, qty })}
                    unit={rowData.measurement} />,
        },
    ];

    const [products, setData] = useState(Array<ProductType>());

    useEffect(() => {

        const processData = (returnedData: ProductType[]) => {
            let newData: ProductType[];
            newData = [...returnedData];
            setData(newData);
        };

        if (!props.category) {
            GetProducts()
                .then(result => processData(result.data));
        }
        else {
            GetProductsByCategory(props.category)
                .then(result => processData(result.data));
        }
    }, [props.category]);


    return (
        <div className="border border-lightgray border-1 mh-100">
            <MaterialTable title={t("Products")} data={products} columns={columns}
                options={{ search: true, paging: true, filtering: true, exportButton: false }}

            />

        </div>
    );

}