import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService';
import { GetCustomerOrder, GetCustomerOrderLines } from './DataAccess';
import { OrderType, OrderLineType } from './Models'
import { Table, Button } from 'react-bootstrap'
import MaterialTable from "material-table";

export function CustomerOrder() {

    const { t } = useTranslation();
    const [orders, SetOrders] = useState(Array<OrderType>(0));
    const [orderLines, SetOrderLines] = useState(Array<OrderLineType>(0));
    const [selectedOrderId, SetSelectedOrderId] = useState(0);

    useEffect(() => {
        authService.getUser().then(
            (user) => {
                GetCustomerOrder(user.name)
                    .then((result) => {
                        let newData: OrderType[];
                        newData = [...result.data];
                        SetOrders(newData);
                        if (newData.length > 0){
                            showLines(newData[0].orderId);
                        }
                    });
            }
        );

    }, []);

    const showLines = (orderId: number) => {

        GetCustomerOrderLines(orderId)
            .then((result) => {
                let newData: OrderLineType[];
                newData = [...result.data];
                SetOrderLines(newData);
                SetSelectedOrderId(orderId);
            });

    }
    const columns = [
        {
            title: t('Order Id'),
            render: (rowData: OrderType) => <Button variant="outline-primary w-100"
                onClick={() => showLines(rowData.orderId)}>{rowData.orderId}</Button>,
        },
        {
            title: t("Date"),
            render: (rowData: OrderType) => new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "numeric",
                day: "2-digit"
            }).format(new Date(rowData.txDate)),
        },
        {
            title: t("Customer"),
            field: "customerName",
        },
        {
            title: t("Contact"),
            field: "contactName",
        },
        {
            title: t("Phone"),
            field: "contactTel",
        },
        {
            title: t("Email"),
            field: "contactEmail",
        },

    ];

    return (
        <div>
            <div className="w-100"><h3 className="text-center" >{t('Orders History')}</h3></div>
            <MaterialTable title={t("Orders")} data={orders} columns={columns}
                options={{ search: true, paging: true, filtering: true, exportButton: true }} />
            <div className='shadow-sm'>
                <Table bordered className='mt-2' size="sm">
                    <thead>
                        <tr><th colSpan={6}>{selectedOrderId === 0 ? "" : t("Order x details", { selectedOrderId: selectedOrderId })}</th></tr>
                        <tr>
                            <th>{t("Line")}</th>
                            <th>{t("Code")}</th>
                            <th>{t("Product")}</th>
                            <th>{t("Qty")}</th>
                            <th>{t("Price")}</th>
                            <th>{t("Amount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderLines.map(item =>
                                <tr key={item.lineNo}>
                                    <td>{item.lineNo}</td>
                                    <td>{item.productCode}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.price}</td>
                                    <td>{item.amount}</td>

                                </tr>
                            )

                        }
                    </tbody>
                </Table></div>
        </div>
    )

}