import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { ShoppingApp } from './components/ShoppingApp';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { ShoppingCart } from './components/ShoppingCart'
import { CustomerOrder } from './components/CustomerOrder'


import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <AuthorizeRoute path='/' exact component={ShoppingApp} />
        <AuthorizeRoute path='/shoppingapp' component={ShoppingApp} />
        <AuthorizeRoute path='/shoppingcart' component={ShoppingCart} />
        <AuthorizeRoute path='/customerOrder' component={CustomerOrder} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
